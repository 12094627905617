import React, { useCallback, useEffect } from 'react';
import { useTelegram } from '../../../services';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';
import { setAvatar } from '../../../redux/reducers/avatars.reducer';
import { fetchTgFullUser } from '../../../services/telegram/utils';
import { useQueryClient } from '@tanstack/react-query';

type IPayload = {
	chatId: number;
	senderId?: number;
	accessHash?: string;
	isEnabled: boolean;
};

export const useGetSenderAvatar = ({
	chatId,
	senderId,
	accessHash,
	isEnabled,
}: IPayload) => {
	const tg = useTelegram();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	const storeAvatars = useSelector((state: RootStore) => state.avatars);

	const updateAvatar = useCallback(
		(id: number, avatarUri?: string) => {
			if (!avatarUri?.length || storeAvatars[`${id}`] === avatarUri) {
				return;
			}

			dispatch(setAvatar({ [id]: avatarUri }));
		},
		[storeAvatars, dispatch],
	);

	useEffect(() => {
		if (isEnabled && tg && senderId && accessHash && chatId) {
			(async function () {
				try {
					const fullUserInfo = await fetchTgFullUser(
						queryClient,
						tg,
						senderId.toString(),
						accessHash,
					);
					if (!fullUserInfo?.fullInfo) {
						return;
					}
					const { profilePhoto, fallbackPhoto } =
						fullUserInfo.fullInfo;
					const senderAvatar =
						profilePhoto?.thumbnail?.dataUri ||
						fallbackPhoto?.thumbnail?.dataUri ||
						'';

					updateAvatar(senderId, senderAvatar);
				} catch (error) {
					console.log(
						'getFullUserInfo sender avatar in card error: ',
						error,
					);
				}
			})();
		}
	}, [isEnabled, tg, senderId, accessHash, chatId]);
};
