export type State =
	| { type: "initial" }
	| { type: "checking_for_existing_refresh_token", tgid: number, did: string }
	| { type: "waiting_for_otp", tgid: number, did: string }
	| { type: "polling_refresh", tgid: number, did: string, otp: string }
	| { type: "waiting_for_jwt", tgid: number, did: string }
	| { type: "authed", tgid: number, did: string, jwt: string, jwtExpiry: number, jwtRetriesLeft: number }
	// errors
	| { type: "otp_request_failed", tgid: number, did: string, error: unknown }
	| { type: "refresh_token_request_failed", tgid: number, did: string, otp: string, error: unknown }
	| { type: "jwt_request_failed", tgid: number, did: string, error: unknown }
	| { type: "auth_expired_no_network", tgid: number, did: string, exponentialRetries: number }

export type Msg =
	| { type: "ui.auth_requested", tgid: number, did: string }
	| { type: "otp_arrived", otp: string }
	| { type: "refresh_token_arrived", token: string }
	| { type: "jwt_arrived", jwt: string, expiry: number }
	| { type: "refresh_token_expired" }
	// errors
	| { type: "otp_request_failed", error: unknown }
	| { type: "refresh_token_request_failed", error: unknown }
	| { type: "jwt_request_failed", now: number, error: unknown }

export type Cmd =
	| { type: 'request_otp'; tgid: number }
	| { type: 'send_otp'; tgid: number; otp: string; did: string }
	| { type: 'request_jwt'; tgid: number /* , token: string */ } // token is not needed due to cookies
	| { type: 'refresh_jwt'; tgid: number; /* token: string, */ at: number } // token is not needed due to cookies
	// backwards compatibility
	| { type: 'setExchangingCodeLoading'; value: boolean }
	| { type: 'redirectToDashboard' };


const JWT_RETRIES = 5
const JWT_RETRY_DELAY = 3 * 1000
const JWT_REFRESH_BEFORE_EXPIRY = 30 * 1000

export function init(): [State, ...Cmd[]]
{
	return [{ type: "initial" }]
}
export function update(state: State, msg: Msg): [State, ...Cmd[]]
{
	// console.log("update", state.type, msg.type, state, msg)
	let [state2, ...cmds] = _update(state, msg)
	// console.log(`updated ${state.type} + ${msg.type} => ${state2.type}`, state2, cmds)
	return [state2, ...cmds]
}
function _update(state: State, msg: Msg): [State, ...Cmd[]]
{
	switch (state.type)
	{
		case "initial":
			switch (msg.type)
			{
				case "ui.auth_requested":
					return [
						{ type: "checking_for_existing_refresh_token", tgid: msg.tgid, did: msg.did },
						{ type: "request_jwt", tgid: msg.tgid },
						{ type: "setExchangingCodeLoading", value: true },
					]
			}
			break
		case "checking_for_existing_refresh_token":
			switch (msg.type)
			{
				case "jwt_request_failed":
				case "refresh_token_expired":
					return [
						{ type: "waiting_for_otp", tgid: state.tgid, did: state.did },
						{ type: "request_otp", tgid: state.tgid },
					]
				case "jwt_arrived":
					return [
						{ type: "authed", tgid: state.tgid, did: state.did, jwt: msg.jwt, jwtExpiry: (msg.expiry * 1000), jwtRetriesLeft: JWT_RETRIES },
						{ type: "setExchangingCodeLoading", value: false },
						{ type: "refresh_jwt", tgid: state.tgid, at: (msg.expiry * 1000) - JWT_REFRESH_BEFORE_EXPIRY },
						{ type: "redirectToDashboard" },
					]
			}
			break
		case "waiting_for_otp":
			switch (msg.type)
			{
				case "otp_arrived":
					return [
						{ type: "polling_refresh", tgid: state.tgid, did: state.did, otp: msg.otp },
						{ type: "send_otp", tgid: state.tgid, otp: msg.otp, did: state.did }
					]
				case "otp_request_failed":
					return [
						{ type: "otp_request_failed", tgid: state.tgid, did: state.did, error: msg.error },
						{ type: "setExchangingCodeLoading", value: false },
					]
			}
			break
		case "otp_request_failed":
			switch (msg.type)
			{
				case "ui.auth_requested":
					return [
						{ type: "waiting_for_otp", tgid: msg.tgid, did: state.did },
						{ type: "request_otp", tgid: msg.tgid },
						{ type: "setExchangingCodeLoading", value: true },
					]
			}
			break
		case "polling_refresh":
			switch (msg.type)
			{
				case "refresh_token_arrived":
					return [
						{ type: "waiting_for_jwt", tgid: state.tgid, did: state.did },
						{ type: "request_jwt", tgid: state.tgid }
					]
				case "refresh_token_request_failed":
					return [
						{ type: "refresh_token_request_failed", tgid: state.tgid, did: state.did, otp: state.otp, error: msg.error },
						{ type: "setExchangingCodeLoading", value: false },
					]
			}
			break
		case "refresh_token_request_failed":
			switch (msg.type)
			{
				case "ui.auth_requested":
					return [
						{ type: "polling_refresh", tgid: state.tgid, did: state.did, otp: state.otp },
						{ type: "send_otp", tgid: state.tgid, otp: state.otp, did: state.did },
						{ type: "setExchangingCodeLoading", value: true },
					]
			}
			break
		case "waiting_for_jwt":
			switch (msg.type)
			{
				case "jwt_arrived":
					return [
						{ type: "authed", tgid: state.tgid, did: state.did, jwt: msg.jwt, jwtExpiry: (msg.expiry * 1000), jwtRetriesLeft: JWT_RETRIES },
						{ type: "setExchangingCodeLoading", value: false },
						{ type: "refresh_jwt", tgid: state.tgid, at: (msg.expiry * 1000) - JWT_REFRESH_BEFORE_EXPIRY },
						{ type: "redirectToDashboard" },
					]
				case "jwt_request_failed":
					return [
						{ type: "jwt_request_failed", tgid: state.tgid, did: state.did, error: msg.error },
						{ type: "setExchangingCodeLoading", value: false },
					]
				case "refresh_token_expired":
					return [
						{ type: "waiting_for_otp", tgid: state.tgid, did: state.did },
						{ type: "request_otp", tgid: state.tgid },
						{ type: "setExchangingCodeLoading", value: true },
					]
			}
			break
		case "jwt_request_failed":
			switch (msg.type)
			{
				case "ui.auth_requested":
					return [
						{ type: "waiting_for_jwt", tgid: state.tgid, did: state.did, },
						{ type: "request_jwt", tgid: state.tgid },
						{ type: "setExchangingCodeLoading", value: true },
					]
			}
			break
		case "authed":
			switch (msg.type)
			{
				case "jwt_arrived":
					return [
						{ ...state, jwt: msg.jwt, jwtExpiry: (msg.expiry * 1000) },
						{ type: "refresh_jwt", tgid: state.tgid, at: (msg.expiry * 1000) - JWT_REFRESH_BEFORE_EXPIRY },
						{ type: "setExchangingCodeLoading", value: false },
					]
				case "jwt_request_failed":
					let isExpired = msg.now >= state.jwtExpiry
					if (isExpired)
					{
						if (state.jwtRetriesLeft <= 0) // we probably don't have network right now, start exponential backoff
						{
							let exponentialBackoff = Math.pow(2, -state.jwtRetriesLeft) * JWT_RETRY_DELAY
							return [
								{ type: "auth_expired_no_network", tgid: state.tgid, did: state.did, exponentialRetries: 0 },
								{ type: "refresh_jwt", tgid: state.tgid, at: msg.now + exponentialBackoff },
							]
						}
						return [
							{ type: "auth_expired_no_network", tgid: state.tgid, did: state.did, exponentialRetries: 0 },
							{ type: "refresh_jwt", tgid: state.tgid, at: msg.now + JWT_RETRY_DELAY },
						]
					}
					return [
						{ ...state, jwtRetriesLeft: state.jwtRetriesLeft - 1 },
						{ type: "refresh_jwt", tgid: state.tgid, at: msg.now + JWT_RETRY_DELAY },
					]
				case "refresh_token_expired":
					return [
						{ type: "waiting_for_otp", tgid: state.tgid, did: state.did },
						{ type: "request_otp", tgid: state.tgid },
						{ type: "setExchangingCodeLoading", value: true },
					]
			}
			break
		case "auth_expired_no_network":
			switch (msg.type)
			{
				case "jwt_arrived":
					return [
						{ type: "authed", tgid: state.tgid, did: state.did, jwt: msg.jwt, jwtExpiry: (msg.expiry * 1000), jwtRetriesLeft: JWT_RETRIES },
						{ type: "refresh_jwt", tgid: state.tgid, at: (msg.expiry * 1000) - JWT_REFRESH_BEFORE_EXPIRY },
					]
				case "jwt_request_failed":
					let exponentialBackoff = Math.pow(2, state.exponentialRetries) * JWT_RETRY_DELAY
					return [
						{ type: "auth_expired_no_network", tgid: state.tgid, did: state.did, exponentialRetries: state.exponentialRetries + 1 },
						{ type: "refresh_jwt", tgid: state.tgid, at: msg.now + exponentialBackoff },
					]
			}
			break
	}
	console.error(`Unhandled state ${state.type} + ${msg.type}`)
	return [state]
}
