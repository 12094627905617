import React, { createContext, useCallback, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { msgErr, MsgsProxy, useTeaSimple } from "../tesm"
import { init, update, State, Msg } from "./tea"
import { Auth } from "../../api/workers"
import { poll } from "../poll"
import { useTelegram } from "../../services"
import { BOT_USERNAME } from "../../tg-config"
import { setExchangingCodeLoading } from "../../redux/reducers/login-flow.reducer"
import { SupabaseAuthWrapper } from "./wrapper"

const AuthContext = createContext<{ state: State, msgs: MsgsProxy<Msg> } | undefined>(undefined)
const LegacyRedirectContext = createContext<{ setRedirectToDashboard: (redirectToDashboard: () => void) => void } | undefined>(undefined)

async function pollRefreshToken(query: { tgid: number, otp: string, did: string })
{
	let token = await poll(async () =>
	{
		let result = await Auth.refreshToken({ query: query })
		return result.token || undefined
	}, 750, 10)

	return { token }
}

export const AuthProvider: React.FCC<{ sendBotOtp: (otp: string) => Promise<void>, redirectToDashboard: () => void }> = ({ children, ...props }) =>
{
	// backwards compatibility with existing spaghetti
	const dispatch = useDispatch()

	const [state, msgs] = useTeaSimple(init, update, {

		request_otp: ({ tgid }) => Auth.otp({ query: { id: tgid } })
			.then(msgs.otp_arrived)
			.catch(msgErr(msgs.otp_request_failed)),

		send_otp: (cmd) =>
		{
			// console.log("[CONTEXT] send_otp", cmd)
			props.sendBotOtp(cmd.otp).then(() => pollRefreshToken(cmd)
				.then(msgs.refresh_token_arrived)
				.catch(msgErr(msgs.refresh_token_request_failed))
			).catch(msgErr(msgs.refresh_token_request_failed))
		},

		request_jwt: ({ tgid }) => Auth.jwt({ query: { id: tgid } })
			.then(res => res.expired ? msgs.refresh_token_expired({}) : msgs.jwt_arrived(res))
			.catch(error => msgs.jwt_request_failed({ error, now: Date.now() })),

		refresh_jwt: ({ tgid, at }) => setTimeout(() =>
			Auth.jwt({ query: { id: tgid } })
				.then(res => res.expired ? msgs.refresh_token_expired({}) : msgs.jwt_arrived(res))
				.catch(error => msgs.jwt_request_failed({ error, now: Date.now() }))
			, at - Date.now()),

		redirectToDashboard: props.redirectToDashboard,

		// backwards compatibility with existing spaghetti
		setExchangingCodeLoading: ({ value }) => dispatch(setExchangingCodeLoading(value)),
	})

	const value = useMemo(() => ({ state, msgs }), [state, props.sendBotOtp])

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	)
}
export const AuthProviderWithTg: React.FCC = ({ children }) =>
{
	const tg = useTelegram()
	const [shouldSend, setShouldSend] = React.useState<string | undefined>()
	const [sendPromiseResolve, setSendPromiseResolve] = React.useState<() => void>()

	const sendCodeToBot = useCallback((token: string) => 
	{
		setShouldSend(token)
		return new Promise<void>(resolve => setSendPromiseResolve(resolve))
	}, [setShouldSend, setSendPromiseResolve])

	const [redirectToDashboard, setRedirectToDashboard] = React.useState<() => void>()
	const redirectToDashboardHandler = useCallback(() =>
	{
		redirectToDashboard?.()
	}, [redirectToDashboard])

	useEffect(() =>
	{
		// console.log(`[AuthProviderWithTg] shouldSend: ${shouldSend}, tg: ${!!tg}`)

		if (!shouldSend)
			return
		if (!tg)
			return

		// console.log(`[AuthProviderWithTg] sending code to bot: ${shouldSend}`)
		tg.actions.proxy.openChatByUsername({
			username: BOT_USERNAME,
			startParam: shouldSend,
		})
		setShouldSend(undefined)
		sendPromiseResolve?.()
	}, [shouldSend, tg, sendPromiseResolve])

	return (
		<LegacyRedirectContext.Provider value={{ setRedirectToDashboard }}>
			<AuthProvider sendBotOtp={sendCodeToBot} redirectToDashboard={redirectToDashboardHandler}>
				<SupabaseAuthWrapper>
					{children}
				</SupabaseAuthWrapper>
			</AuthProvider>
		</LegacyRedirectContext.Provider>
	)
}

export function useAuthContext()
{
	let ctx = React.useContext(AuthContext)
	if (!ctx)
		throw new Error("useAuthContext must be used within a AuthProvider")

	return ctx
}

export function legacy_hookRedirectToReactRouter()
{
	let navigate = useNavigate()
	let ctx = React.useContext(LegacyRedirectContext)
	if (!ctx)
		throw new Error("legacy_hookRedirectToReactRouter must be used within a LegacyRedirectContext")
	
	useEffect(() =>
	{
		ctx.setRedirectToDashboard(() => navigate("/dashboard"))
	}, [ctx, navigate])
}
