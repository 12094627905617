export function withQuery(url: string, query: Record<string, string | number | string[] | number[]>): string
{
	let params = new URLSearchParams()
	for (let key in query)
		Array.isArray(query[key])
		? query[key].forEach(value => params.append(key, value.toString()))
		: params.append(key, query[key].toString())

	if (url.includes("?"))
		return url + "&" + params.toString()

	return url + "?" + params.toString()
}

type _ExtractParam<Path, NextPart> = Path extends `:${infer Param}` ? Record<Param, string | number> & NextPart : NextPart;
export type ExtractUrlParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? _ExtractParam<Segment, ExtractUrlParams<Rest>>
  : _ExtractParam<Path, {}>

export function withParams<Url extends string, P extends ExtractUrlParams<Url>>(url: Url, params: P): string
{
	if (!params)
	{
		if (hasUrlParams(url))
			throw new Error(`Params are required for ${url}`)

		return url
	}
	
	let path = url as string
	for (let key in params)
		path = path.replace(`:${key}`, params[key] as string)

	// check that all params were replaced
	let withoutProtocol = path.replace(/:\/\//, "")
	if (withoutProtocol.includes(":"))
		throw new Error(`Not all params were replaced in ${path}`)

	return path
}

// function extractUrlParams(url: string): keyof ExtractUrlParams<typeof url>[]
// {
// 	let matches = url.match(/:\w+/g)
// 	if (!matches)
// 		return [] as any

// 	return matches.map(match => match.slice(1)) as any
// }

function hasUrlParams(url: string)
{
	let withoutProtocol = url.replace(/:\/\//, "")
	if (withoutProtocol.includes(":"))
		return true

	return false
}
