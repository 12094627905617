import React from 'react';
import { AppRoutes } from './App.routes';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/createStore';
import { AuthProviderWithTg } from './state/auth/context'

const App: React.FC = () => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<AuthProviderWithTg>
					<AppRoutes />
				</AuthProviderWithTg>
			</PersistGate>
		</Provider>
	);
};

export default App;
