import React from 'react';
import {
	Flex,
	Avatar,
	Text,
	HStack,
	IconButton,
	Skeleton,
	VStack,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

interface ITeamMemberProps {
	id?: number;
	name: string;
	username?: string;
	avatar?: string;
	onDelete?: VoidFunction;
	includeDelete?: boolean;
}

export const TeamMember: React.FC<ITeamMemberProps> = ({
	id,
	username,
	name,
	onDelete,
	includeDelete,
	avatar,
}) => {
	const { isSynced } = useSelector((state: RootStore) => state.syncState);
	const storedAvatar = useSelector(
		(state: RootStore) => state.avatars[id || 0],
	);
	const storedUserData = useSelector(
		(state: RootStore) => state.telegramState.users[id || 0],
	);

	return (
		<Flex
			justifyContent="space-between"
			align="center"
			p="12px"
			borderBottom="1px solid"
			borderBottomColor="gray.15"
			columnGap="12px"
			_last={{ borderBottom: 'none' }}>
			<HStack spacing="12px">
				<Skeleton
					boxSize="24px"
					borderRadius="50%"
					startColor="transparentLight.10"
					endColor="transparentLight.40"
					isLoaded={isSynced}>
					<Avatar boxSize="24px" src={avatar || storedAvatar} />
				</Skeleton>
				<Skeleton
					width={isSynced ? 'auto' : '170px'}
					borderRadius="2px"
					startColor="transparentLight.10"
					endColor="transparentLight.40"
					isLoaded={isSynced}>
					<VStack spacing={0} align="flex-start">
						<Text
							color="primary"
							fontSize="13px"
							fontWeight="500"
							whiteSpace="nowrap">
							{name}
						</Text>

						<Text color="gray.40" fontSize="13px" fontWeight="400">
							{username ||
								'@' + (storedUserData?.username || 'unknown')}
						</Text>
					</VStack>
				</Skeleton>
			</HStack>
			{includeDelete ? (
				<IconButton
					aria-label="Delete"
					variant="ghost"
					w="24px"
					minW="24px"
					h="24px"
					borderRadius="4px"
					onClick={onDelete}>
					<Icon
						name="delete-red-bin"
						height="16px"
						width="16px"
						color={colors.tertiary}
					/>
				</IconButton>
			) : null}
		</Flex>
	);
};
