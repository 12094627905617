/* eslint-disable indent */
import React, { useEffect, PropsWithChildren } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';
import { RootStore } from '../../redux/createStore';
import { AuthModal } from '../AuthModal/AuthModal';
import { setShowLoginFlow } from '../../redux/reducers/login-flow.reducer';
import { TelegramHiddenFrame } from '../../components';
import { Sidebar } from '../../components/MainLayout/components';
import { ChatPageHeader } from '../ChatPageHeader/ChatPageHeader';
import { useGetWorkspaces } from '../../queries';
import {
	setOnboardingVisibility,
	setOnboardingActiveStep,
} from '../../redux/reducers/onboarding.reducer';
import { OnboardingSteps } from '../../types';
import {
	OnboardingModal,
	InviteMembersModal,
	SyncSettingsModal,
	CreateWorkspaceModal,
} from '../../modals';

export const FrameWrapper: React.FC<PropsWithChildren> = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { data: workspacesData } = useGetWorkspaces();

	const { accessToken } = useSelector((state: RootStore) => state.auth.token);
	const { user } = useSelector((state: RootStore) => state.auth);
	const { workSpace, isOnboarded } = useSelector(
		(state: RootStore) => state.auth.user,
	);
	const { showTelegramLoginModal } = useSelector(
		(state: RootStore) => state.loginFlow,
	);
	const { isOpen } = useSelector((state: RootStore) => state.onboarding);

	const navigate = useNavigate();

	const isChat = pathname.includes('/chat');
	const isOnboarding = pathname.includes('/onboarding');
	const isSelectWorkspace = pathname.includes('/select-workspace');
	const isLogin = pathname.includes('/login');

	useEffect(() => {
		if (!accessToken) {
			dispatch(setShowLoginFlow(true));
		}
	}, [accessToken, dispatch]);

	useEffect(() => {
		if (!accessToken) {
			navigate('/login', { replace: true });
		}
	}, [accessToken, navigate]);

	useEffect(() => {
		if (
			!workSpace &&
			user.id &&
			!location.pathname.includes('/login') &&
			!workspacesData?.value?.length
		) {
			dispatch(setOnboardingVisibility(true));
		} else if (
			!isOnboarded &&
			!isOpen &&
			user.id &&
			!location.pathname.includes('/login')
		) {
			dispatch(setOnboardingActiveStep(OnboardingSteps.SYNC_CHATS));
			dispatch(setOnboardingVisibility(true));
		}
	}, [workSpace, isOnboarded, isOpen, user, workspacesData, dispatch]);

	return (
		<>
			<Flex minHeight="100vh" bgImage="url('/background-noise.png')">
				{accessToken &&
				!isLogin &&
				!isOnboarding &&
				!isSelectWorkspace ? (
					<Sidebar
						data={
							user || {
								avatarThumbnailLink: '',
								avatarUrl: '',
								firstName: '',
								id: 0,
								isOnboarded: true,
								lastName: '',
							}
						}
					/>
				) : null}
				<Box
					w={
						showTelegramLoginModal
							? '100%'
							: {
									base: '100%',
									md:
										isOnboarding || isSelectWorkspace
											? '100%'
											: 'calc(100vw - 240px)',
								}
					}
					flexDirection="column"
					pr={3}
					display={showTelegramLoginModal ? 'flex' : 'block'}>
					<Box flex={1} className="frame-top" />
					{isChat && accessToken ? <ChatPageHeader /> : null}
					<Flex>
						<Box
							width={showTelegramLoginModal ? '100%' : 0}
							className="frame-left"
						/>
						<TelegramHiddenFrame />
						{accessToken ? (
							<Outlet />
						) : (
							<Box
								width={showTelegramLoginModal ? '100%' : 0}
								className="frame-right"
							/>
						)}
					</Flex>
					<Box flex={1} className="frame-bottom" />
				</Box>
			</Flex>
			<AuthModal />
			{showTelegramLoginModal ? (
				<Box
					position="fixed"
					left={0}
					top={0}
					width="100vw"
					zIndex={5}
					height="100vh"
					background="blackAlpha.600"
				/>
			) : null}
			<OnboardingModal />
			<InviteMembersModal />
			<SyncSettingsModal />
			<CreateWorkspaceModal />
		</>
	);
};
