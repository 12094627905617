/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useTelegram } from '../../../services';
import {
	updateTelegramChatState,
	updateTelegramUserState,
} from '../../../redux/reducers/telegram-state.reducer';
import { RootStore } from '../../../redux/createStore';
import { TgUserData } from '../../../types';
import { checkForGroupChat } from '../utils';

type ChatCollectedData = {
	senderAvatar: string;
	chatAvatar: string;
	chatMembers: TgUserData[];
};

export function useChat(id: string, isEnabled: boolean) {
	const tg = useTelegram();
	const dispatch = useDispatch();

	const storeUsers = useSelector(
		(state: RootStore) => state.telegramState.users,
	);
	const { telegramUserId } = useSelector(
		(state: RootStore) => state.auth.user,
	);

	const updateStoreUser = (id: number, data: TgUserData) =>
		dispatch(updateTelegramUserState({ id, data }));

	const getChatData = async (chatId: string) => {
		const isGroupChat = checkForGroupChat(chatId);

		const res = await tg?.custom.proxy.getChatWithLastMessageById(+chatId);

		if (!res?.chat) {
			throw new Error('not found chat');
		}

		const collectedData: ChatCollectedData = {
			senderAvatar: '',
			chatAvatar: '',
			chatMembers: [],
		};

		if (!isGroupChat && res.msg?.senderId === chatId && res.chat) {
			const privateChatUsersData = [
				{
					id: res.id,
					username: res.chat.usernames?.[0]?.username || null,
				},
				{
					id: telegramUserId,
					username: storeUsers[`${telegramUserId}`]?.username || null,
				},
			];
			privateChatUsersData.map(user => {
				if (!user.id) {
					return;
				}

				updateStoreUser(user.id, user);
				collectedData.chatMembers = [
					...collectedData.chatMembers,
					user,
				];
			});
		}

		if (
			!isGroupChat &&
			res.msg?.senderId !== chatId &&
			res.lastMessageUserInfo &&
			telegramUserId
		) {
			const userData = {
				id: telegramUserId,
				username:
					res.lastMessageUserInfo.usernames?.[0]?.username || null,
			};
			updateStoreUser(telegramUserId, userData);
			collectedData.chatMembers = [
				...collectedData.chatMembers,
				userData,
			];
		}

		const neededData = {
			id: res.id,
			isLoaded: !!res.chat,
			title: res.chat.title,
			hasAccess:
				!!res.chat && !res.chat?.isNotJoined && !res.chat.isForbidden,
			type: res.chat?.type,
			accessHash: res.chat.accessHash,
			unreadCount: res.chat?.unreadCount || 0,
			lastMessage: res.msg?.id
				? {
						id: res.msg.id,
						text: res.msg?.content.text?.text || null,
						content: res.msg?.content,
						date: res.msg.date,
					}
				: null,
			chatMembers: [],
			isRefetchDisabled: true,
			sender: res.lastMessageUserInfo?.id
				? {
						id: +res.lastMessageUserInfo.id,
						firstName: res.lastMessageUserInfo.firstName || '',
						lastName: res.lastMessageUserInfo.lastName || '',
						accessHash: res.lastMessageUserInfo.accessHash,
					}
				: null,
		};

		if (res.lastMessageUserInfo) {
			dispatch(
				updateTelegramChatState({
					id: +chatId,
					data: neededData,
				}),
			);
		} else {
			throw new Error('no last message info');
		}

		return neededData;
	};

	const { data, isLoading, error } = useQuery({
		queryKey: [`tg1-chat-${id}`],
		queryFn: () => getChatData(id),
		retryDelay: 1000,
		retry(failureCount) {
			if (failureCount >= 5) {
				dispatch(
					updateTelegramChatState({
						id: +id,
						data: {
							id: +id,
							isLoaded: true,
							isChatFullyLoaded: true,
							isRefetchDisabled: true,
						},
					}),
				);
				return false;
			}
			return true;
		},
		refetchOnWindowFocus: false,
		enabled: isEnabled,
	});

	return {
		data,
		isLoading,
		error,
	};
}
