import { useEffect } from "react"
import { useAuthContext } from "./context"
import supabase from "../../services/supabase"

/**
 * Supabase Auth Provider
 * - automatically sets supabase session based on the current jwt
 * - when jwt changes, updates the session
 * - mutates supabase global object
 */
export const SupabaseAuthWrapper: React.FCC = ({ children }) =>
{
	const { state } = useAuthContext()
	if (state.type != "authed")
		return children
	
	return <InternalWrapper jwt={state.jwt}>{children}</InternalWrapper>
}
const InternalWrapper: React.FCC<{ jwt: string }> = ({ children, jwt }) =>
{
	useEffect(() =>
	{
		if (!jwt)
			return

		// console.log(`[SupabaseAuthProvider] setting session with jwt: ${jwt}`)
		supabase.auth.setSession({
			access_token: jwt,
			refresh_token: "",
		})
	}, [jwt])
	
	return children
}
