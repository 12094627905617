import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, VStack, FlexProps, Box } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getNavLinkItems } from '../constants';
import { SidebarNavItem } from './SidebarNavItem';
import { SidebarCompanyHeader } from './SidebarCompanyHeader';
import { LogoutButton } from './LogoutButton';
import { TaskCardSolo } from '../../TaskCard/TaskCardSolo';
import { TeamChatPlaceholder } from '../../TaskCard/TeamChatPlaceholder';
import { SidebarChatView } from './SidebarChatView';
import { SidebarSettingsView } from './SidebarSettingsView';
import {
	ApiResponse,
	CurrentUser,
	NotificationsCountUpdatePayload,
} from '../../../types';
import { SelectTeamChatModal } from '../../../modals';
import {
	useGetNotificationsCount,
	useGetUserTasksCount,
} from '../../../queries';
import { QueryKeys } from '../../../constants';
import {
	invoke,
	SignalRMethods,
	SignalRTopics,
	useSignalREffect,
} from '../../../services';
import { RootStore } from '../../../redux/createStore';
import { useGetMatchedCardId, useGetMatchedWorkspaceId } from '../../../hooks';
import { UserInformBlock } from './UserInformBlock';

interface ISidebarContent extends FlexProps {
	data: CurrentUser;
}

export const SidebarContent: React.FC<ISidebarContent> = ({
	data,
	...rest
}) => {
	const { workSpace } = data;
	const navigate = useNavigate();
	const location = useLocation();
	const queryClient = useQueryClient();
	const workspaceId = useGetMatchedWorkspaceId();
	const cardId = useGetMatchedCardId();

	const { signalRConnected } = useSelector(
		(state: RootStore) => state.signalR,
	);

	const { isDashboard, isTasks } = useMemo(() => {
		const dashboardRouteRegex = /^\/\d+$/;
		const tasksRouteRegex = /^\/\d+\/tasks$/;
		const isDashboard = dashboardRouteRegex.test(location.pathname);
		const isTasks = tasksRouteRegex.test(location.pathname);

		return { isDashboard, isTasks };
	}, [location.pathname]);

	const { data: tasksCountData } = useGetUserTasksCount(
		isDashboard || isTasks,
	);

	const { data: notificationsCountData } = useGetNotificationsCount();

	const showSidebarSettingsView = location.pathname.includes('settings');
	const showSidebarChatView =
		workSpace?.teamChat?.cardId !== cardId &&
		location.pathname.includes('chat');

	const onRedirect = (chatTelegramId?: number, cardId?: number) => {
		if (!chatTelegramId) {
			return;
		}

		navigate(`/${workspaceId}/chat/${cardId}`, {
			state: {
				chatTelegramId,
				isTeamChat: true,
				id: cardId,
			},
		});
	};

	useEffect(() => {
		if (workspaceId && (isTasks || isDashboard)) {
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
			});
		}
	}, [isTasks, isDashboard, workspaceId, queryClient]);

	useSignalREffect(
		SignalRTopics.NotificationCountUpdate,
		(data: NotificationsCountUpdatePayload) => {
			if (!workspaceId) {
				return;
			}
			const { isAdded } = data;

			queryClient.setQueryData<ApiResponse<{ count: number }>>(
				[QueryKeys.GET_NOTIFICATIONS_COUNT, workspaceId],
				oldData => {
					if (oldData) {
						return {
							...oldData,
							value: {
								count: isAdded
									? oldData.value.count + 1
									: Math.max(0, oldData.value.count - 1),
							},
						};
					}
					return oldData;
				},
			);
		},
		[workspaceId],
	);

	useEffect(() => {
		if (signalRConnected && workspaceId) {
			invoke(SignalRMethods.WorkspaceJoinChat, workspaceId);
		}

		return () => {
			if (workspaceId && signalRConnected) {
				invoke(SignalRMethods.WorkspaceLeaveChat, workspaceId);
			}
		};
	}, [signalRConnected, workspaceId]);

	const getActivityCount = useCallback(
		(id: number) => {
			switch (id) {
				case 2:
					return tasksCountData?.value || 0;
				case 3:
					return notificationsCountData?.value?.count || 0;
				default:
					return 0;
			}
		},
		[tasksCountData?.value, notificationsCountData?.value],
	);

	return (
		<React.Fragment>
			<Flex
				direction="column"
				alignItems={{
					base: 'space-between',
					md: 'flex-start',
				}}
				h="100vh"
				px="8px"
				pt="10px"
				pb="12px"
				w={{ base: 'full', md: '240px' }}
				flexShrink={0}
				overflowY="auto"
				sx={{
					'&::-webkit-scrollbar': {
						width: 0,
					},
					'&::-webkit-scrollbar-track': {
						width: 0,
					},
					'&::-webkit-scrollbar-thumb': {
						width: 0,
					},
				}}
				{...rest}>
				{workSpace ? (
					<SidebarCompanyHeader
						name={workSpace.name}
						imageLink={workSpace.avatarThumbnailUrl || ''}
						wrapperProps={{
							display: {
								base: 'none',
								md: !showSidebarSettingsView ? 'flex' : 'none',
							},
						}}
						showSidebarChatView={showSidebarChatView}
					/>
				) : null}

				<Flex
					direction="column"
					alignItems="stretch"
					position="relative"
					display={{ base: 'flex', md: 'none' }}>
					<Flex
						justifyContent="center"
						alignItems="center"
						mx={{ base: 0, md: 4 }}>
						{workSpace ? (
							<SidebarCompanyHeader
								name={workSpace.name}
								imageLink={workSpace.avatarThumbnailUrl || ''}
								showSidebarChatView={showSidebarChatView}
							/>
						) : null}
					</Flex>
				</Flex>
				{showSidebarChatView ? null : !showSidebarSettingsView ? (
					<VStack
						spacing={{ base: 3, md: '2px' }}
						mt={{ base: '50px', md: '30px' }}
						mx="auto"
						align="stretch"
						w={{ base: '300px', md: 'full' }}>
						{getNavLinkItems(workspaceId)
							.filter(elem =>
								// exclude settings for non admin users
								workSpace?.isAdmin ? true : elem.id !== 5,
							)
							.map(elem => (
								<SidebarNavItem
									key={elem.id}
									name={elem.name}
									path={elem.path}
									iconName={elem.iconName}
									location={location.pathname}
									isSoon={elem.isSoon}
									isActive={elem.path === location.pathname}
									activity={getActivityCount(elem.id)}
								/>
							))}
						<LogoutButton />
					</VStack>
				) : null}
				<UserInformBlock isEnabled={isDashboard} />
				{showSidebarChatView ? <SidebarChatView /> : null}
				{showSidebarSettingsView ? <SidebarSettingsView /> : null}
				{!showSidebarSettingsView ? (
					<Box
						mt="auto"
						w="100%"
						display={{ base: 'none', md: 'block' }}>
						{workSpace?.teamChat ? (
							<TaskCardSolo
								isDragging={false}
								cardName={workSpace?.teamChat?.name || ''}
								chatTelegramId={workSpace?.teamChat?.id || 0}
								onRedirect={() =>
									onRedirect(
										workSpace?.teamChat?.id,
										workSpace?.teamChat?.cardId,
									)
								}
								isTeamChat={true}
								cardId={workSpace.teamChat.cardId}
							/>
						) : (
							<TeamChatPlaceholder
								teamChatData={workSpace?.teamChat}
								isAdmin={workSpace?.isAdmin}
							/>
						)}
					</Box>
				) : null}
			</Flex>
			<SelectTeamChatModal />
		</React.Fragment>
	);
};
