import { createClient, SupabaseClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { Database } from '../types';

const VITE_SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;
const VITE_SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!VITE_SUPABASE_URL || !VITE_SUPABASE_ANON_KEY) {
	throw new Error('VITE_SUPABASE_URL and VITE_SUPABASE_ANON_KEY must be set');
}

const supabase: SupabaseClient<Database> = createClient(
	VITE_SUPABASE_URL,
	VITE_SUPABASE_ANON_KEY,
);

export const useSupabaseWithAuth = () => {
	const authToken = Cookies.get('supabase_auth_token');
	const client = createClient(VITE_SUPABASE_URL, VITE_SUPABASE_ANON_KEY, {
		global: {
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
		},
	});
	return client;
};

export default supabase;
