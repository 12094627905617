import { useState, useEffect, useCallback } from 'react'
import { sleep } from '../utils'

type ResultSuccess<T> = {
	status: 'success'
	data: T
}
type ResultError = {
	status: 'error'
	error: Error
}
type UsePollingResult<T> = ResultSuccess<T> | ResultError | { status: 'idle' } | { status: 'loading' }

/**
 * React Hook that polls an async function at a given interval
 */
export function usePolling<T>(
	asyncFunction: () => Promise<T | undefined>,
	pollInterval: number,
	maxRetries: number
): UsePollingResult<T>
{
	const [result, setResult] = useState<UsePollingResult<T>>({ status: 'idle' })
	const [retries, setRetries] = useState(0)

	let timeoutId: NodeJS.Timeout | undefined = undefined

	const poll = useCallback(async () =>
	{
		try
		{
			if (timeoutId)
				clearTimeout(timeoutId)

			setResult({ status: 'loading' })
			const result = await asyncFunction()

			if (result !== undefined)
			{
				setResult({ status: 'success', data: result })
				setRetries(0) // Reset retries on success
			}
			else if (retries < maxRetries)
			{
				setRetries(prev => prev + 1)
				if (timeoutId) clearTimeout(timeoutId)
				timeoutId = setTimeout(poll, pollInterval)
			}
			else
			{
				throw new Error('Max retries reached without a defined result')
			}
		}
		catch (err)
		{
			setResult({ status: 'error', error: err instanceof Error ? err : new Error(String(err)) })
		}
	}, [asyncFunction, pollInterval, maxRetries, retries])

	useEffect(() =>
	{
		poll()

		// Cleanup function to clear the timeout if the component unmounts
		return () =>
		{
			// This is conceptual in a real scenario, you'd need to keep track of the timeout ID
			clearTimeout(timeoutId)
		}
	}, [poll])

	return result
}

/**
 * Normal function that polls an async function at a given interval
 */
export async function poll<T>(
	asyncFunction: () => Promise<T | undefined>,
	pollInterval: number,
	maxRetries: number
): Promise<Exclude<T, undefined>>
{
	let retries = 0

	while (retries < maxRetries)
	{
		try
		{
			const result = await asyncFunction()

			if (typeof result != "undefined")
			{
				return result as any
			}
			else
			{
				retries++
				await sleep(pollInterval)
			}
		}
		catch (err)
		{
			throw err
		}
	}

	throw new Error('Max retries reached without a defined result')
}

export default usePolling