import React, { useMemo } from 'react';
import { Box, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { EventsIndicator } from '../../EventsIndicator/EventsIndicator';
import { LabelWithImage } from '../../LabelWithImage/LabelWithImage';
import {
	convertBase64ToBlob,
	getAvatarBg,
	getLastMessageContentLabel,
} from '../../../utils';
import { TgLastMessage } from '../../../types';

interface CardBodyProps {
	hasAccess?: boolean;
	unreadCount?: number;
	message?: TgLastMessage | null;
	name?: string;
	avatarUri?: string | null;
	isLoaded?: boolean;
	chatId: number;
}

export const CardBody: React.FC<CardBodyProps> = ({
	hasAccess,
	unreadCount,
	message,
	name,
	avatarUri,
	isLoaded,
	chatId,
}) => {
	const blobUrl = useMemo(
		() =>
			avatarUri
				? URL.createObjectURL(convertBase64ToBlob(avatarUri))
				: '',
		[avatarUri],
	);

	const messageContent = useMemo(
		() => getLastMessageContentLabel(message?.content),
		[message],
	);

	if (!hasAccess) {
		return null;
	}

	return (
		<VStack
			w="100%"
			align="flex-start"
			isTruncated={true}
			spacing={1}
			pb={1.5}>
			<HStack
				w="100%"
				spacing={1}
				justify="space-between"
				position="relative">
				<Skeleton
					isLoaded={isLoaded}
					maxW="100%"
					w="100%"
					startColor="gray.40"
					endColor="gray.20"
					h={isLoaded ? 'inherit' : '20px'}>
					<HStack
						flex={1}
						pr={unreadCount ? '16px' : '0px'}
						isTruncated={true}>
						<LabelWithImage
							label={name || ''}
							imageLink={blobUrl}
							includeAvatar={true}
							avatarProps={{
								bg: getAvatarBg(chatId),
							}}
						/>
					</HStack>
				</Skeleton>
				<EventsIndicator
					count={unreadCount || 0}
					wrapperProps={{
						position: 'absolute',
						right: 0,
						bg: 'red.90',
					}}
					textProps={{
						color: 'white',
					}}
				/>
			</HStack>
			{messageContent ? (
				<Skeleton
					maxW="100%"
					w="100%"
					h={isLoaded ? 'inherit' : '20px'}
					startColor="gray.40"
					endColor="gray.20"
					isLoaded={isLoaded}>
					<Box mt={1} maxW="100%">
						<Text isTruncated={true}>{messageContent}</Text>
					</Box>
				</Skeleton>
			) : null}
		</VStack>
	);
};
