import { ExtractUrlParams, withParams, withQuery } from "../utils/url"
import { number, object, string, null as znull, literal, union, undefined as undef, type z } from "zod"

function endpoint<Url extends string, Res, P extends ExtractUrlParams<Url>, Q extends {} = {}, B extends {} = {}>(method: RequestInit["method"], url: Url, responseSchema: z.ZodType<Res>, querySchema?: z.ZodType<Q>, bodySchema?: z.ZodType<B>)
{
	type _P = keyof P extends never ? {} : { params: P }
	type _Q = keyof Q extends never ? {} : { query: Q }
	type _B = keyof B extends never ? {} : { body: B }
	type Opts = _P & _Q & _B
	return async (opts: Opts, init?: RequestInit) =>
	{
		// unforunate workaround for TS not being able to infer the type of opts
		let { query, body, params } = Object.assign({ query: undefined, body: undefined, params: undefined }, opts)
		
		// validate and prepare
		querySchema?.parse(query)
		bodySchema?.parse(body)

		let u = withQuery(withParams(url, params as any /* unfortunately, but safe */), query || {})

		// check init and set headers
		if (!init)
			init = {}

		if (!init.method)
			init.method = method

		let headers = new Headers(init.headers)
		let contentType = headers.get("Content-Type")
		init.headers = headers

		if (!contentType && body)
		{
			contentType = "application/json"
			headers.set("Content-Type", contentType)
			init.headers = headers
			init.body = JSON.stringify(body)
		}

		let res = await fetch(u, init)
		if (!res.ok)
			throw new Error(res.statusText)

		// TODO: what if response is not JSON?
		return responseSchema.parse(await res.json())
	}
}
function get<Url extends string, Res, P extends ExtractUrlParams<Url>, Q extends {}>(url: Url, validators: { response: z.ZodType<Res>, query?: z.ZodType<Q>})
{
	return endpoint<Url, Res, P, Q>("GET", url, validators.response, validators.query)	
}
function post<Url extends string, Res, P extends ExtractUrlParams<Url>, Q extends {}, B extends {}>(url: Url, validators: { response: z.ZodType<Res>, query?: z.ZodType<Q>, body?: z.ZodType<B>})
{
	return endpoint<Url, Res, P, Q, B>("POST", url, validators.response, validators.query, validators.body)
}

export const Auth = {
	otp: get("/auth/otp", {
		query: object({
			id: number()
		}),
		response: object({
			otp: string(),
			expire: number()
		}),
	}),
	refreshToken: get("/auth/refresh-token", {
		query: object({
			tgid: number(),
			otp: string(),
			did: string()
		}),
		response: object({
			token: string().or(znull()).optional()
		}),
	}),
	jwt: get("/auth/jwt", {
		query: object({
			id: number()
		}),
		response: object({
			jwt: string(),
			expiry: number(),
			expired: union([literal(false), undef(), znull()]).optional().nullable()
		}).or(object({
			expired: literal(true)
		})),
	})
}
