import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../../api/api.base';
import { ApiResponse, OnboardingSteps } from '../../../types';
import {
	setOnboardingActiveStep,
	setCreateWorkspaceModalOpen,
} from '../../../redux/reducers/onboarding.reducer';
import { Alerter } from '../../../utils';
import { QueryKeys } from '../../../constants';
import { setWorkspaceId } from '../../../redux/reducers/auth.reducer';

export const useCreateWorkspace = (isIsolated: boolean) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return useMutation<ApiResponse<number>, unknown, FormData>({
		mutationKey: [QueryKeys.CREATE_WORKSPACE],
		mutationFn: payload => API.post('/api/workspaces', payload),
		onSuccess: async data => {
			if (data.success) {
				if (!isIsolated) {
					dispatch(
						setOnboardingActiveStep(OnboardingSteps.INVITE_TEAM),
					);
				} else {
					dispatch(setCreateWorkspaceModalOpen(false));
				}
				await queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_WORKSPACES],
				});
				await queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_CURRENT_USER],
				});
				navigate(`/${data.value}`);
				setWorkspaceId(data.value);
			} else {
				Alerter.error(
					data.errors?.[0]?.message ||
						'Error occurred while creating workspace',
				);
			}
		},
	});
};
