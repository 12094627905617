import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, Text, HStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, InputText, TeamMember } from '../../../components';
import { useInviteMembersToWorkspace } from '../queries';
import { useGetCurrentUser, useSetUserTimeZone } from '../../../queries';
import { setAuthUser } from '../../../redux/reducers/auth.reducer';
import { UserInvitePayload } from '../../../types';
import {
	useGetMatchedWorkspaceId,
	useGetTeammateByUsername,
} from '../../../hooks';
import { Alerter, getCurrentUserTimeZone } from '../../../utils';
import { RootStore } from '../../../redux/createStore';
import { setAvatar } from '../../../redux/reducers/avatars.reducer';
import { updateTelegramUserState } from '../../../redux/reducers/telegram-state.reducer';

type HandleForm = {
	username: string;
};

interface IInviteTeamProps {
	isIsolated?: boolean;
	isActiveStep?: boolean;
}

export const InviteTeam: React.FC<IInviteTeamProps> = ({
	isIsolated = false,
	isActiveStep = false,
}) => {
	const dispatch = useDispatch();
	const storeAvatars = useSelector((state: RootStore) => state.avatars);
	const workspaceId = useGetMatchedWorkspaceId();
	const inviteMembersMutation = useInviteMembersToWorkspace({
		isIsolated,
	});
	const { data, isLoading } = useGetCurrentUser(!isIsolated && isActiveStep);
	const setUserTimeZoneMutation = useSetUserTimeZone();

	const [selectedTeammates, setSelectedTeammates] = useState<
		{
			id?: string;
			firstName?: string;
			lastName?: string;
			username: string;
			avatar?: string;
		}[]
	>([]);

	const { register, handleSubmit, reset } = useForm<HandleForm>();

	const updateUserData = useCallback(
		(id: number, username: string, avatarUri?: string) => {
			dispatch(
				updateTelegramUserState({
					id,
					data: {
						username: username.replace('@', ''),
					},
				}),
			);

			if (!avatarUri?.length || storeAvatars[`${id}`] === avatarUri) {
				return;
			}

			dispatch(setAvatar({ [id]: avatarUri }));
		},
		[dispatch, storeAvatars],
	);

	const { onAddHandle, isLoading: isUserFetchLoading } =
		useGetTeammateByUsername(value => {
			const matchedTeammate = selectedTeammates?.find(
				user => user.id === value.id,
			);
			if (matchedTeammate) {
				Alerter.warning(
					`User ${value.firstName}${value.lastName ? ` ${value.lastName}` : ''} is already on the list`,
				);
				reset({ username: '' });
				return;
			}
			setSelectedTeammates(prev => [...prev, value]);
			reset({ username: '' });
		});

	useEffect(() => {
		if (!isLoading && data?.value && !isIsolated) {
			dispatch(setAuthUser(data.value));
		}
		if (!isLoading && !data?.value?.timeZoneId && !isIsolated) {
			const userTimeZone = getCurrentUserTimeZone();
			setUserTimeZoneMutation.mutate(userTimeZone);
		}
	}, [data, isLoading, dispatch, isIsolated]);

	const onAdd = async (data: HandleForm) => {
		onAddHandle(data.username);
	};

	const onInviteMembers = () => {
		const payload = selectedTeammates
			.map(elem => {
				if (elem.id) {
					const payload: UserInvitePayload = {
						telegramUserId: +elem.id,
						firstName: elem.firstName || 'unknown',
						lastName: elem.lastName || null,
					};
					return payload;
				}
				return null;
			})
			.filter(elem => elem !== null) as UserInvitePayload[];

		if (!workspaceId) {
			Alerter.error('Workspace ID is undefined');
			return;
		}
		inviteMembersMutation.mutate(
			{ workspaceId, payload },
			{
				onSuccess: data => {
					if (!data?.success) {
						return;
					}
					selectedTeammates.map(elem => {
						if (!elem.id) {
							return;
						}
						updateUserData(+elem.id, elem.username, elem.avatar);
					});
				},
			},
		);
	};

	const onDeleteMember = (id?: string) => {
		setSelectedTeammates(prev => prev.filter(elem => elem.id !== id));
	};

	return (
		<Flex flexDirection={'column'} align={'center'} gap={'30px'}>
			<Box>
				<Text
					fontWeight={'600'}
					fontSize={'20px'}
					lineHeight={'24px'}
					textAlign={'center'}>
					Invite your team
				</Text>
				<Text textAlign={'center'} mt={'16px'} color={'secondary'}>
					Get the most out of your workspace by inviting your team.
					Use Telegram usernames to add people.
				</Text>
			</Box>
			<Box w={'full'} position="relative">
				<form onSubmit={handleSubmit(onAdd)}>
					<HStack align="flex-end">
						<InputText {...register('username')} label="USERNAME" />
						<Button
							variant="outline"
							type="submit"
							isLoading={isUserFetchLoading}
							borderRadius="4px"
							background="gray.10"
							color="secondary"
							leftIcon={
								<Icon name="plus" width="14px" height="14px" />
							}>
							Add
						</Button>
					</HStack>
				</form>
				{selectedTeammates.length ? (
					<Box
						borderRadius="8px"
						bg="gray.10"
						border="1px solid"
						mt="32px"
						maxH="500px"
						overflowY="auto"
						borderColor="gray.20">
						{selectedTeammates.map(elem => (
							<TeamMember
								key={elem.id}
								username={elem.username}
								name={
									elem.firstName + ' ' + (elem.lastName || '')
								}
								avatar={elem.avatar}
								onDelete={() => onDeleteMember(elem.id)}
								includeDelete={true}
							/>
						))}
					</Box>
				) : null}
			</Box>

			<Button
				w={'65%'}
				onClick={onInviteMembers}
				isLoading={inviteMembersMutation.isPending}
				isDisabled={
					isIsolated ? !selectedTeammates?.length : undefined
				}>
				{isIsolated ? 'Invite' : 'Continue'}
			</Button>
		</Flex>
	);
};
