import React, { useCallback, useEffect } from 'react';
import { ApiChatType, TgUserData } from '../../../types';
import { useTelegram } from '../../../services';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateTelegramChatState,
	updateTelegramUserState,
} from '../../../redux/reducers/telegram-state.reducer';
import { checkForGroupChat } from '../utils';
import { RootStore } from '../../../redux/createStore';
import { setAvatar } from '../../../redux/reducers/avatars.reducer';
import { fetchTgFullChat, fetchTgUser } from '../../../services/telegram/utils';
import { useQueryClient } from '@tanstack/react-query';

type IPayload = {
	chatId: number;
	accessHash?: string;
	type?: ApiChatType;
	title: string;
	isEnabled: boolean;
	msgSenderId?: number;
};

export const useGetFullChatInfo = ({
	chatId,
	accessHash,
	title,
	type,
	msgSenderId,
	isEnabled,
}: IPayload) => {
	const queryClient = useQueryClient();
	const tg = useTelegram();
	const dispatch = useDispatch();

	const storeAvatars = useSelector((state: RootStore) => state.avatars);

	const updateAvatar = useCallback(
		(id: number, avatarUri?: string) => {
			if (!avatarUri?.length || storeAvatars[`${id}`] === avatarUri) {
				return;
			}

			dispatch(setAvatar({ [id]: avatarUri }));
		},
		[storeAvatars, dispatch],
	);

	const filterUniqueById = (arr: TgUserData[]) => {
		const seenIds = new Set<number>();
		return arr.filter(el => {
			if (seenIds.has(+el.id)) {
				return false;
			}
			seenIds.add(+el.id);
			return true;
		});
	};
	const isGroupChat = checkForGroupChat(chatId.toString());

	useEffect(() => {
		if (isGroupChat && isEnabled && tg && chatId && type && accessHash) {
			(async function () {
				try {
					const collectedData = {
						chatAvatar: '',
						chatMembers: [] as TgUserData[],
					};
					const fullChatInfo = await fetchTgFullChat({
						accessHash,
						chatId,
						queryClient,
						tg,
						type,
						title,
					});
					if (fullChatInfo?.users?.length) {
						fullChatInfo.users.map(user => {
							const userData = {
								id: +user.id,
								username: user.usernames?.[0]?.username || null,
							};
							dispatch(
								updateTelegramUserState({
									id: +user.id,
									data: userData,
								}),
							);
							collectedData.chatMembers = [
								...collectedData.chatMembers,
								userData,
							];
						});
					}
					if (
						fullChatInfo?.fullInfo?.profilePhoto?.thumbnail?.dataUri
					) {
						const chatAvatar =
							fullChatInfo.fullInfo.profilePhoto.thumbnail
								.dataUri;
						collectedData.chatAvatar = chatAvatar;
					}

					if (
						!collectedData.chatAvatar &&
						!collectedData.chatMembers.length
					) {
						return;
					}

					updateAvatar(+chatId, collectedData.chatAvatar);

					const uniqueChatMembers = filterUniqueById(
						collectedData.chatMembers,
					);
					dispatch(
						updateTelegramChatState({
							id: +chatId,
							data: {
								chatMembers: uniqueChatMembers,
								isChatFullyLoaded: true,
							},
						}),
					);
				} catch (error) {
					console.log('getFullChatInfo in card err: ', error);
				}
			})();
		}
	}, [isEnabled, tg, chatId, isGroupChat, title, accessHash, type, dispatch]);

	useEffect(() => {
		if (isEnabled && tg && !isGroupChat && msgSenderId !== chatId) {
			(async () => {
				try {
					const collectedData = {
						chatAvatar: '',
						chatMembers: [] as TgUserData[],
					};
					const privateChatUserInfo = await fetchTgUser(
						queryClient,
						tg,
						chatId,
					);

					if (privateChatUserInfo?.userShortInfo) {
						const userData = {
							id: chatId,
							username:
								privateChatUserInfo.userShortInfo.usernames?.[0]
									?.username || null,
						};
						dispatch(
							updateTelegramUserState({
								id: chatId,
								data: userData,
							}),
						);
						collectedData.chatMembers = [
							...collectedData.chatMembers,
							userData,
						];
					}
					if (privateChatUserInfo?.userFullInfo) {
						const {
							profilePhoto: privateChatProfilePhoto,
							fallbackPhoto: privateChatFallbackPhoto,
						} = privateChatUserInfo.userFullInfo;
						const privateChatAvatar =
							privateChatProfilePhoto?.thumbnail?.dataUri ||
							privateChatFallbackPhoto?.thumbnail?.dataUri ||
							'';

						collectedData.chatAvatar = privateChatAvatar;
					}
					const uniqueChatMembers = filterUniqueById(
						collectedData.chatMembers,
					);
					updateAvatar(+chatId, collectedData.chatAvatar);
					dispatch(
						updateTelegramChatState({
							id: +chatId,
							data: {
								chatMembers: uniqueChatMembers,
								isChatFullyLoaded: true,
							},
						}),
					);
				} catch (error) {
					console.log('getTgUser in card err: ', error);
				}
			})();
		}
	}, [isEnabled, tg, isGroupChat, msgSenderId, chatId, dispatch]);
};
