import { Dispatch, useCallback, useEffect, useMemo, useReducer, useState } from "react"

/**
 * React Hook that creates a TEA-like reducer from State, Msg, and Cmd
 */
export function useTea<State, Msg, Cmd>(
	init: () => [State, ...Cmd[]],
	update: (state: State, msg: Msg) => [State, ...Cmd[]],
	handleCmd: (cmd: Cmd) => void
): [State, (msg: Msg) => void]
{
	let [initState, ...initCmds] = init()
	
	const [cmdQueue, setCmdQueue] = useState<Cmd[]>(initCmds)
	const reducer = useCallback((state: State, msg: Msg) =>
	{
		const [newState, ...cmds] = update(state, msg)
		setCmdQueue((prev) => [...prev, ...cmds])
		return newState
	}, [update, setCmdQueue])

	const [state, dispatch] = useReducer(reducer, initState)

	useEffect(() =>
	{
		if (cmdQueue.length > 0)
		{
			const [cmd] = cmdQueue
			if (cmd)
			{
				setCmdQueue(cmdQueue.slice(1))
				handleCmd(cmd)
			}
		}
	}, [cmdQueue, handleCmd])

	return [state, dispatch]
}

export function useTeaSimple<State, Msg extends { type: string }, Cmd extends { type: string }>(
	init: () => [State, ...Cmd[]],
	update: (state: State, msg: Msg) => [State, ...Cmd[]],
	cmds: { [key in Cmd["type"]]: (cmd: Extract<Cmd, {type: key}>) => any },
): readonly [State, { [key in Msg["type"]]: (params: Omit<Extract<Msg, {type: key}>, "type">) => Extract<Msg, {type: key}> }]
{
	const handler = useMemo(() => createHandler(cmds), [cmds])
	const [state, dispatch] = useTea(init, update, handler)
	const msgs = useMemo(() => createMsgs(dispatch), [dispatch])
	const res = useMemo(() => [state, msgs] as const, [state, msgs])
	return res
}

export function createHandler<Cmd extends { type: string }>(funcs: { [key in Cmd["type"]]: (cmd: Extract<Cmd, {type: key}>) => void })
{
	return (cmd: Cmd) =>
	{
		// console.log("handling", cmd)

		const handler = funcs[cmd.type as Cmd["type"]]
		return handler?.(cmd as any)
	}
}

export type MsgsProxy<Msg extends { type: string }> = { [key in Msg["type"]]: (params: Omit<Extract<Msg, {type: key}>, "type">) => Extract<Msg, {type: key}> }
export function createMsgs<Msg extends { type: string }>(dispatch: Dispatch<Msg>): MsgsProxy<Msg>
{
	let proxy = new Proxy({}, {
		get(target, prop)
		{
			return (params: any) =>
			{
				// console.log("dispatching", prop, params)
				dispatch({ type: prop as string, ...params })
			}
		}
	})
	return proxy as any
}

export function msgErr(f: (m: { error: unknown }) => void)
{
	return (error: unknown) =>
	{
		f({ error })
	}
}
