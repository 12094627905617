import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from '../redux/createStore';
import { Alerter, setAuthHeaderToken, handleAxiosError } from '../utils';
import { refreshToken, logout } from '../redux/reducers/auth.reducer';
import { ApiResponse, RefreshResponse } from '../types';

interface RetryQueueItem {
	resolve: (value?: unknown) => void;
	reject: (error?: unknown) => void;
	config: AxiosRequestConfig;
}

export default function intercept(): void {
	axios.defaults.baseURL =
		import.meta.env.VITE_API_BASE_URL || 'http://localhost:5173';

	let isRefreshing = false;
	const refreshAndRetryQueue: RetryQueueItem[] = [];

	axios.interceptors.request.use(async req => {
		const accessToken = store.getState().auth?.token?.accessToken;

		if (accessToken) {
			if (req.headers) {
				req.headers.Authorization = `Bearer ${accessToken}`;
			} else {
				delete req.headers['Authorization'];
			}
		}
		return req;
	});

	axios.interceptors.response.use(undefined, async err => {
		const {
			config,
			response: { status },
		} = err;
		const originalRequest = config;
		if (status === 401) {
			if (!isRefreshing) {
				isRefreshing = true;
				try {
					const refreshResult: AxiosResponse<
						ApiResponse<RefreshResponse>
					> = await axios.post('/api/auth/refresh-token', {
						accessToken: store.getState().auth.token.accessToken,
						refreshToken: store.getState().auth.token.refreshToken,
					});

					if (!refreshResult?.data?.success) {
						logoutUser();
						return;
					}

					setAuthHeaderToken(refreshResult.data.value.accessToken);

					refreshAndRetryQueue.forEach(
						({ config, resolve, reject }) => {
							axios
								.request(config)
								.then(response => resolve(response))
								.catch(err => reject(err));
						},
					);

					refreshAndRetryQueue.length = 0;

					store.dispatch(refreshToken(refreshResult.data.value));

					return axios(originalRequest);
				} catch (error) {
					logoutUser();
					handleAxiosError(err);
				} finally {
					isRefreshing = false;
				}
			}

			if (originalRequest?.url === '/api/auth/refresh-token') {
				logoutUser();
				return;
			}

			return new Promise<unknown>((resolve, reject) => {
				refreshAndRetryQueue.push({
					config: originalRequest,
					resolve,
					reject,
				});
			});
		}

		return Promise.reject(err);
	});

	const logoutUser = () => {
		store.dispatch(logout());
		Alerter.error('You need login again to continue');
	};
}
